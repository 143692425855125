<script>
export default {};
</script>
<template>
  <section class="join">
    <div class="curly">
      <img src="@/assets/home/decoration/curly.svg" />
    </div>
    <img src="@/assets/home/decoration/curly2.svg" class="curly2" />
    <img src="@/assets/home/decoration/book.svg" class="book" />
    <div class="join__container">
      <div class="join__text">
        <h4>Mari bergabung bersama kami &amp; dapatkan berbagai manfaatnya</h4>
        <p class="text-muted">
          Ekosistem digital untuk mewadahi teman-teman penulis, penelaah,
          penerbit, dan lainnya
        </p>
        <button class="btn btn-secondary mt-4">
          Daftar Sekarang
        </button>
      </div>
      <img src="@/assets/home/model-join-2.png" />
    </div>
  </section>
</template>
<style lang="scss" scoped>
@import "../../assets/styles/_breakpoint.scss";

.join {
  background: #fff7e9;
  position: relative;
  overflow: hidden;

  & .curly2 {
    position: absolute;
    top: 2rem;
    left: 60%;
    @include breakpoint("md") {
      left: 50%;
    }
    @include breakpoint("lg") {
      left: 40%;
    }
  }
  & .book {
    position: absolute;
    bottom: 0;
    left: 20%;
  }

  & .curly {
    position: absolute;
    left: 0;
    top: 0;
    & img {
      height: 100%;
    }
  }

  &__container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 0.75rem;
    min-height: 50vh;
    @include breakpoint("md") {
      flex-direction: row;
      align-items: center;
    }
    & > img {
      object-fit: contain;
      width: 400px;
      z-index: 1;
      @include breakpoint("md") {
        align-self: flex-end;
        width: 350px;
      }
      @include breakpoint("lg") {
        padding: 0 2rem;
        width: 450px;
      }
      @include breakpoint("xl") {
        padding: 0 4rem;
        width: 700px;
      }
    }
  }
  &__text {
    padding: 2rem 1.5rem;
    z-index: 1;
    @include breakpoint("lg") {
      padding-left: 3rem;
    }
    & h4 {
      font-size: 27px;
      font-weight: 700;
      @include breakpoint("md") {
        font-size: 35px;
      }
      @include breakpoint("lg") {
        font-size: 35px;
      }
      @include breakpoint("xl") {
        font-size: 45px;
      }
    }
  }
}
</style>
