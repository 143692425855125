<script>
import SvgIcon from "../common/SvgIcon.vue";
export default {
  components: { SvgIcon },
  data: () => ({
    items: [
      {
        icon: "seal-check",
        title: "Resmi Terdaftar",
        description:
          "Dengan mendaftar di platform SIBI, teman-teman pelaku perbukuan mendapat peluang untuk dikenali dan dikurasi oleh staff perbukuan, serta dipublikasi sebagai partner resmi Kemendikbudristek",
      },
      {
        icon: "pencil-blue",
        title: "Hasil Penilaian",
        description:
          "Memantau hasil penilaian buku terbaru dan terkurasi secara akurat. Informasi tersebut mendapat dimanfaatkan untuk pengadaan buku dalam ruang lingkup dana BOS.",
      },
      {
        icon: "books",
        title: "Pembinaan / Pelatihan",
        description:
          "Berpeluang mendapatkan informasi pembinaan terbaru serta menjadi orang yang terdepan dalam pendaftaran pelatihan. Terus tingkatkan skill terkait perbukuan.",
      },
      {
        icon: "note",
        title: "Informasi Kebijakan",
        description:
          "Mendapatkan informasi kebijakan dan pengumuman terkait kegiatan perbukuan.",
      },
      {
        icon: "id-card",
        title: "Sertifikasi",
        description:
          "Mendapat peluang untuk mengikuti program sertifikasi secara online.",
      },
      {
        icon: "file-pdf",
        title: "Unduh PDF",
        description:
          "Mendapatkan akses untuk mengunduh versi cetak dari buku yang diterbitkan Kemendikbudristek",
      },
    ],
  }),
};
</script>
<template>
  <section class="benefit">
    <div class="benefit__header">
      <h4>Manfaat Bergabung</h4>
      <p class="mt-3 text-muted">
        Mari berkolaborasi dan dapatkan berbagai peluang dalam ekosistem SIBI
      </p>
    </div>
    <div class="benefit__list">
      <div
        class="benefit__list-wrapper"
        v-for="(item, idx) in items"
        :key="idx"
      >
        <div class="benefit__list--icon">
          <svg-icon :name="item.icon"></svg-icon>
        </div>
        <h4 class="">
          {{ item.title }}
        </h4>
        <p class="text-muted">
          {{ item.description }}
        </p>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
@import "../../assets/styles/_breakpoint.scss";

.benefit {
  padding: 2rem;
  @include breakpoint("lg") {
    padding: 3rem 4rem;
  }
  margin: 0 0 4rem;
  &__header {
    text-align: center;
    & h4 {
      font-size: 40px;
      font-weight: 800;
    }
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    margin-top: 4rem;
    @include breakpoint("md") {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    &--icon {
      display: inline-block;
      padding: 0.5rem;
      border-radius: 0.75rem;
      margin-bottom: 1.5rem;
      background: rgba($color: #b4c9ff, $alpha: 0.1);
    }
    &-wrapper {
      padding: 0 2rem 2rem 0;
      --border-width: 0;
      --padding: 0;
      @include breakpoint("md") {
        --border-width: 2px;
        --padding: 2rem;
      }
      border-right: var(--border-width) dashed #eef4ff;
      & > p {
        padding-bottom: 2rem;
        @include breakpoint("md") {
          padding: 0;
        }
      }
      &:nth-child(n + 2) {
        padding-left: var(--padding);
      }
      &:nth-child(n + 4) {
        padding-top: var(--padding);
      }
      &:nth-child(-n + 3) {
        padding-bottom: var(--padding);
        border-bottom: var(--border-width) dashed #eef4ff;
      }
      &:nth-child(3n + 3) {
        border-right: none;
      }
      &:nth-child(4) {
        padding-left: 0 !important;
      }
    }
  }
}
</style>
