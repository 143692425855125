<script>
export default {};
</script>

<template>
  <section class="hero">
    <div class="hero__text">
      <h1>
        Selamat Datang Pelaku Perbukuan
      </h1>
      <p class="text-muted">
        Ekosistem digital untuk mewadahi teman-teman penulis, penelaah,
        penerbit, dan lainnya. Bertujuan menjadi pusat informasi dan komunikasi
        dua arah terkait kegiatan perbukuan nasional
      </p>
      <div class="d-flex gap">
        <router-link class="btn btn-secondary mr-3" to="/register">
          Daftar Sekarang
        </router-link>
        <a href="#pembinaan" class="btn btn-outline-secondary">
          Pelajari Lebih Lanjut
        </a>
      </div>
    </div>
    <img src="@/assets/home/model-2.png" />
  </section>
</template>

<style lang="scss" scoped>
@import "../../assets/styles/_breakpoint.scss";
.hero {
  min-height: calc(100vh - 90px);
  display: flex;
  flex-direction: column-reverse;
  gap: 1rem;
  background: #f6fcff;
  align-items: center;
  padding: 2rem;
  @include breakpoint("lg") {
    padding: 2rem 5rem;
  }
  @include breakpoint("lg") {
    flex-direction: row;
    height: calc(80vh - 90px);
  }
  &__text {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    @include breakpoint("lg") {
      padding: 0 1rem;
      max-width: 700px;
    }
    @include breakpoint("xl") {
      padding: 0 1rem;
      max-width: none;
    }
    & h1 {
      font-size: 35px;
      font-weight: 700;
      color: var(--primary-500);
      @include breakpoint("sm") {
        font-size: 50px;
      }
      @include breakpoint("lg") {
        font-size: 60px;
      }
    }
  }
  & > img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    @include breakpoint("md") {
      max-width: 500px;
    }
    @include breakpoint("lg") {
      max-width: 400px;
    }
    @include breakpoint("xl") {
      max-width: 50vw;
    }
  }
}
</style>
