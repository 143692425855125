<script>
export default {
  data: () => ({
    partners: [
      { name: "ikapi", url: "https://www.ikapi.org" },
      {
        name: "kurikulum-merdeka",
        url: "https://kurikulum.kemdikbud.go.id/kurikulum-merdeka",
      },
      { name: "merdeka-mengajar", url: "https://guru.kemdikbud.go.id" },
      { name: "belajarid", url: "https://www.belajar.id" },
      {
        name: "merdeka-belajar",
        url: "https://merdekabelajar.kemdikbud.go.id/",
      },
    ],
  }),
};
</script>
<template>
  <section class="partner">
    <h4>Laman Terkait</h4>
    <div class="partner__icon">
      <a
        :href="partner.url"
        v-for="partner in partners"
        :key="partner.name"
        target="_blank"
      >
        <img
          :src="require(`@/assets/home/partner/${partner.name}.png`)"
          :alt="partner.name"
        />
      </a>
    </div>
  </section>
</template>
<style scoped lang="scss">
@import "../../assets/styles/_breakpoint.scss";

.partner {
  text-align: center;
  padding: 2rem;
  margin: 3rem 0;
  @include breakpoint("lg") {
    padding: 3rem 4rem;
  }
  & h4 {
    font-size: 35px;
    margin-bottom: 4rem;
    font-weight: 800;
    @include breakpoint("lg") {
      font-size: 45px;
    }
  }
  &__icon {
    display: flex;
    justify-content: center;
    gap: 4rem;
    flex-direction: column;
    flex-wrap: wrap;
    @include breakpoint("sm") {
      flex-direction: row;
    }

    & img {
      object-fit: contain;
      aspect-ratio: 12/1 !important;
    }
  }
}
</style>
