<template>
  <div>
    <hero-section />
    <pembinaan-section />
    <announcement-section />
    <benefit-section />
    <faq-section />
    <partner-section />
    <join-section />
  </div>
</template>

<script>
import $ from "jquery";
import { mapActions, mapState } from "vuex";
import HeroSection from "../../components/homepage/HeroSection.vue";
import PembinaanSection from "../../components/homepage/PembinaanSection.vue";
import BenefitSection from "../../components/homepage/BenefitSection.vue";
import FaqSection from "../../components/homepage/FaqSection.vue";
import PartnerSection from "../../components/homepage/PartnerSection.vue";
import JoinSection from "../../components/homepage/JoinSection.vue";
import AnnouncementSection from "../../components/homepage/AnnouncementSection.vue";
export default {
  name: "Home",
  components: {
    HeroSection,
    PembinaanSection,
    BenefitSection,
    FaqSection,
    PartnerSection,
    JoinSection,
    AnnouncementSection,
  },
  computed: { ...mapState(["policies"]) },
  methods: {
    ...mapActions(["fetchTotalCatalogue", "fetchAllPolicy"]),
  },
  created() {
    this.fetchTotalCatalogue();
    this.fetchAllPolicy();
  },
  mounted() {
    $(".btn-read").click(function() {
      let url = $(this).attr("data-url");

      $("#embed").attr("src", url);
      $("#modal").modal("show");
      return false;
    });
  },
};
</script>
