<script>
import SvgIcon from "../common/SvgIcon.vue";
export default {
  components: { SvgIcon },
  data: () => ({
    items: [
      {
        icon: "laptop",
        title: "Pembinaan",
        description:
          "Salah satu upaya yang dilakukan oleh Pusat Perbukuan untuk mewujudkan buku bermutu adalah melalui program-program pembinaan pelaku perbukuan dalam hal pembinaan kompetensi dan manajemen perbukuan. ",
        url: "/pelaku/pembinaan",
      },
      {
        icon: "pencil",
        title: "Penilaian",
        description:
          "Memantau hasil penilaian buku terbaru & terkurasi secara akurat. Informasi tersebut dapat dimanfaatkan untuk pengadaan buku dalam ruang lingkup dana BOS",
        url: "/event-penilaian",
      },
      {
        icon: "list-check",
        title: "Kebijakan",
        description:
          "Dapat mengakses informasi mengenai daftar undang-undang, pemendikbud, dan keputusan menteri terkait Perbukuan.",
        url: "/kebijakan",
      },
    ],
  }),
};
</script>

<template>
  <section class="pembinaan" id="pembinaan">
    <div v-for="(item, idx) in items" :key="idx" class="pembinaan-card">
      <div class="pembinaan-card--content">
        <div class="bg-secondary-500/10 pembinaan-card__icon">
          <svg-icon :name="item.icon"></svg-icon>
        </div>
        <h4 class="pembinaan-card__title fw-bold">
          {{ item.title }}
        </h4>
        <p class="pembinaan-card__description text-muted">
          {{ item.description }}
        </p>
      </div>
      <router-link class="text-primary" :to="item.url">
        baca selengkapnya <i class="fas fa-chevron-right" />
      </router-link>
    </div>
  </section>
</template>

<style lang="scss">
@import "../../assets/styles/_breakpoint.scss";

.pembinaan {
  padding: 2rem;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 2rem;
  margin: 3rem 0;
  @include breakpoint("md") {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  @include breakpoint("lg") {
    padding: 3rem 4rem;
  }
  &-card {
    position: relative;
    transition: all 0.2s ease-in;
    padding: 2rem 1rem;
    border-radius: 0.5rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__icon {
      display: inline-block;
      padding: 0.5rem;
      border-radius: 0.75rem;
      margin-bottom: 1.5rem;
    }
    &__title {
      font-weight: 700;
      font-size: 20px;
    }
    &__description {
      font-size: 14px;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      z-index: 999;
      height: 3px;
      background: var(--secondary);
      transition: all 0.2s ease-out;
    }
    &:hover {
      &::before {
        width: 100%;
      }
      box-shadow: 0 1.5rem 3rem var(--primary-100);
    }
  }
}
</style>
