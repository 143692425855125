<script>
export default {
  data: () => ({
    faqs: [
      {
        title: "Cara Mendaftar SIBI",
        description:
          "Silahkan klik Daftar di bagian pojok kanan atas, lalu isi formulir dengan lengkap menggunakan data sebenarnya. Lalu klik tombol Daftar di bagian bawah. Proses pendaftaran selesai.",
      },
      {
        title: "Siapakah pelaku perbukuan",
        description:
          "Pelaku perbukuan terdiri atas Penulis, Penerjemah, Penyadur, Editor, Desainer, Ilustrator, Pencetak, Pengembang Buku Elektronik, Penerbit, dan Toko Buku",
      },
      {
        title: "Mengapa email konfirmasi pendaftaran tidak masuk",
        description:
          "Karena mungkin email kamu tidak aktif, salah memasukan email ketika mendaftar, atau mungkin masuk ke folder promosi atau spam di email kamu",
      },
    ],
  }),
};
</script>
<template>
  <section class="faq">
    <img src="@/assets/home/model-faq-2.png" />
    <div class="faq__content">
      <div class="faq__title">
        <h4>Pertanyaan yang sering diajukan (FAQ)</h4>
        <p class="text-muted">Berbagai pertanyaan yang sering diajukan terkait SIBI.</p>
      </div>
      <div class="accordion" id="accordionExample">
        <div class="accordion-tab mb-4" v-for="(faq, idx) in faqs" :key="idx">
          <div
            class="accordion-header"
            :id="`heading${idx}`"
            data-toggle="collapse"
            :data-target="`#content${idx}`"
            aria-expanded="true"
          >
            <span>
              {{ faq.title }}
            </span>
            <i class="fas fa-chevron-down" />
          </div>

          <div :id="`content${idx}`" class="collapse ">
            <div class="accordion-content text-muted">
              {{ faq.description }}
            </div>
          </div>
        </div>
      </div>
      <button class="btn btn-outline-primary see-more-faq">
        <span>
          Lihat semua pertanyaan
        </span>
        <i class="fas fa-arrow-right" />
      </button>
    </div>
  </section>
</template>
<style scoped lang="scss">
@import "../../assets/styles/_breakpoint.scss";
.faq {
  display: flex;
  flex-direction: column;
  gap: 5rem;
  background: #fbfeff;
  align-items: center;
  padding: 2rem;
  @include breakpoint("lg") {
    padding: 2rem 5rem;
  }
  @include breakpoint("lg") {
    flex-direction: row;
  }

  & > img {
    object-fit: contain;
    display: block;
    max-width: 300px;

    @include breakpoint("lg") {
      display: block;
      max-width: 350px;
    }
    @include breakpoint("xl") {
      max-width: 35vw;
    }
    @include breakpoint("2xl") {
      max-width: none;
    }
  }

  &__content {
    width: 100%;
  }

  &__title {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    margin-bottom: 2rem;
    text-align: center;
    @include breakpoint("lg") {
      text-align: left;
    }
    & h4 {
      font-size: 40px;
      font-weight: 700;
    }
  }
}

.accordion {
  &-tab {
    background: #f3f8ff;
    border-radius: 0.75rem;
  }
  &-header {
    cursor: pointer;
    padding: 1rem;
    font-size: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-content {
    padding: 0 1rem 1rem;
  }
}

.see-more-faq {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.75rem !important;
  width: 100%;
  padding: 1rem;
  font-size: 20px;
  font-weight: 600;
}
</style>
