<script>
import AnnouncementCard from "./AnnouncementCard.vue";
import { ANNOUNCEMENTS } from "@/constants/announcement";
export default {
  components: { AnnouncementCard },
  data: () => ({
    announcements: ANNOUNCEMENTS,
  }),
};
</script>
<template>
  <section class="announcement">
    <div class="announcement__header">
      <div>
        <h5>Pengumuman Terbaru</h5>
        <p class="text-muted">
          Kemendikbudristek siap mendorong pertumbuhan perbukuan di Indonesia
        </p>
      </div>
      <router-link to="/pengumuman">
        <button class="btn btn-outline-primary">Lihat Semua</button>
      </router-link>
    </div>
    <div class="announcement__content">
      <announcement-card :announcement="announcements[0]" />
      <announcement-card class="d-xl-none" :announcement="announcements[1]" />
      <announcement-card class="d-xl-none" :announcement="announcements[2]" />
      <div class="d-xl-block d-none">
        <announcement-card
          vertical
          class="mb-3"
          :announcement="announcements[1]"
        />
        <announcement-card vertical :announcement="announcements[2]" />
      </div>
    </div>
  </section>
</template>
<style scoped lang="scss">
@import "../../assets/styles/_breakpoint.scss";

.announcement {
  background: #fbfeff;
  padding: 2rem;
  @include breakpoint("lg") {
    padding: 3rem 4rem;
  }
  &__header {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 3rem;
    @include breakpoint("sm") {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    & h5 {
      font-size: 40px;
    }
  }
  &__content {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    @include breakpoint("sm") {
      flex-wrap: nowrap;
    }
    & > * {
      width: 100%;
      @include breakpoint("sm") {
        width: 50%;
      }
    }
  }
}
</style>
